import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const ContactMe = () => {
  const { state } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    organization: '',
    howHeard: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(formData);

    if (formData.message !== '') {
      fetch(`/api/${state}/send-email/prospect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (response.ok) {
            setFormData({
              name: '',
              email: '',
              message: '',
              organization: '',
              howHeard: '',
              street: '',
              city: '',
              state: '',
              zipCode: '',
              phone: '',
            });
            // setOpen(false);
          } else {
            alert('There was an issue with your submission. Please try again.');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('An error occurred while sending your message.');
        });
    } else {
      alert('Please fill in all required fields.');
    }
  };

  return (
    <div className="billIdBox">
      <div className="contactMeContainer">
        <h2>Contact Me</h2>
        <form onSubmit={handleSubmit} className="contactForm">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          <label htmlFor="organization">Organization:</label>
          <input
            type="text"
            id="organization"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            required
          />
          <label htmlFor="street">Street:</label>
          <input type="text" id="street" name="street" value={formData.street} onChange={handleChange} required />

          <label htmlFor="city">City:</label>
          <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} required />

          <label htmlFor="state">State:</label>
          <input type="text" id="state" name="state" value={formData.state} onChange={handleChange} required />

          <label htmlFor="zipCode">Zip Code:</label>
          <input type="text" id="zipCode" name="zipCode" value={formData.zipCode} onChange={handleChange} required />

          <label htmlFor="phone">Phone Number:</label>
          <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />

          <label htmlFor="howHeard">How did you hear about us?</label>
          <select id="howHeard" name="howHeard" value={formData.howHeard} onChange={handleChange} required>
            <option value="">Please select</option>
            <option value="socialMedia">Social Media</option>
            <option value="friend">Friend</option>
            <option value="onlineSearch">Online Search</option>
            <option value="other">Other</option>
          </select>

          {(formData.howHeard === 'other' || formData.howHeard === 'friend') && (
            <input type="text" placeholder="Please specify" />
          )}

          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>

          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
  );
};

export default ContactMe;
