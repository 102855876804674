import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from './userService';

const storedUser = JSON.parse(localStorage.getItem('user') || '{}');

const initialState = {
  auth: storedUser.auth || null,
  searchTerm: storedUser.searchTerm || '',
  session: storedUser.session || '',
  sessions: storedUser.sessions || [],
  state: storedUser.state || '',
  features: storedUser.features || [],
  tracks: storedUser.tracks || {},
  redirectUrl: '',
  footer: '',
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: null,
};

export const getUser = createAsyncThunk('user/getUser', async (searchTerm, thunkAPI) => {
  try {
    const user = await userService.getUser(searchTerm);
    return user;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data);
  }
});

export const login = createAsyncThunk('user/login', async (auth, thunkAPI) => {
  const { username, password } = auth;
  try {
    const res = await userService.login(username, password);
    return res;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data);
  }
});

export const fetchUpdatedTracks = createAsyncThunk('user/fetchUpdatedTracks', async (searchTerm, thunkAPI) => {
  try {
    const updatedTracks = await userService.getUpdatedTracks(searchTerm);
    return updatedTracks;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state.auth = null;
      state.features = [];
      state.session = '';
      state.sessions = [];
      state.searchTerm = '';
      state.footer = '';
      state.state = '';
      state.tracks = {};
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = null;
    },
    clearQueries: (state) => {
      state.queries = [];
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    setFooter: (state, action) => {
      state.footer = action.payload;
    },
    logOut: (state) => {
      state.auth = null;
      state.features = [];
      state.session = '';
      state.sessions = [];
      state.searchTerm = '';
      state.state = '';
      state.tracks = {};
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = null;
      localStorage.removeItem('user');
    },
    pushToTracks: (state, action) => {
      // Merge new track data into the existing tracks object
      const { trackId, trackData } = action.payload; // Expect payload to have trackId and trackData
      state.tracks[trackId] = trackData; // Set new track data under trackId key
    },
    setUser: (state, action) => {
      const { auth, features, searchTerm, state: userState, session, sessions, tracks } = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.auth = auth;
      state.features = features;
      state.searchTerm = searchTerm.includes(session) ? searchTerm : updateSearchTerm(searchTerm, userState, session);
      state.session = session;
      state.sessions = sessions;
      state.state = userState;
      state.tracks = tracks; // Directly set tracks as an object
      state.message = null;
      localStorage.setItem('user', JSON.stringify({ ...action.payload, searchTerm: state.searchTerm }));
    },
    setSession: (state, action) => {
      const storedUser = JSON.parse(localStorage.getItem('user') || '{}');
      const session = action.payload;
      const newSearchTerm = state.searchTerm.replace(state.session, session);
      state.searchTerm = newSearchTerm;
      state.session = session;
      localStorage.setItem('user', JSON.stringify({ ...storedUser, session, searchTerm: newSearchTerm }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        const { auth, features, searchTerm, state: userState, session, sessions, tracks } = action.payload;
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.auth = auth;
        state.features = features;
        state.searchTerm = searchTerm.includes(session)
          ? searchTerm
          : searchTerm.includes(auth)
            ? updateSearchTerm(searchTerm, userState, session)
            : searchTerm;
        // state.searchTerm = searchTerm;
        state.session = session;
        state.sessions = sessions;
        state.state = userState;
        state.tracks = tracks; // Directly set tracks as an object
        state.message = null;
        localStorage.setItem('user', JSON.stringify({ ...action.payload, searchTerm: state.searchTerm }));
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { auth, state: userState, features, session, sessions } = action.payload;
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.auth = auth;
        state.state = userState;
        state.features = features;
        state.session = session;
        state.sessions = sessions;
        state.message = null;
        localStorage.setItem('user', JSON.stringify(action.payload));
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUpdatedTracks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUpdatedTracks.fulfilled, (state, action) => {
        const storedUser = JSON.parse(localStorage.getItem('user') || '{}');
        state.isLoading = false;
        state.tracks = action.payload; // Update tracks as an object
        localStorage.setItem('user', JSON.stringify({ ...storedUser, tracks: action.payload }));
      })
      .addCase(fetchUpdatedTracks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

function updateSearchTerm(searchTerm, statePrefix, newSession) {
  const idx = searchTerm.indexOf(statePrefix);
  if (idx === -1) console.error(`the prefix "${statePrefix}" is not in the searh term.`);
  return searchTerm.slice(0, idx + statePrefix.length) + newSession + searchTerm.slice(idx + statePrefix.length + 3);
}

export const { reset, clearQueries, setState, setRedirectUrl, setFooter, logOut, pushToTracks, setUser, setSession } =
  userSlice.actions;
export default userSlice.reducer;
