import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import ThreeArrowsSVG from '../SVGs/ThreeArrowsSVG';
import stateConfig from '../../stateConfig';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { logOut } from '../../features/user/userSlice';

const MainHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useParams();
  const [mobileDropdown, setMobileDropdown] = useState(false);
  const { features, redirectUrl, auth } = useSelector((state) => state.user);
  const handleLogOut = () => {
    dispatch(logOut());
    navigate('/');
  };

  return (
    <>
      <div
        className={`fixed z-30 top-0 w-screen flex items-center justify-between right-0 px-10 xl:px-8 xl:py-[15px] py-[15px] ${
          stateConfig[state.toLowerCase()]?.bannerBlue ? 'bg-darkblue text-white' : 'bg-[#F9FAFB]'
        }  ml-auto shadow-sm h-[75px]`}
      >
        <a href={redirectUrl} className="mr-10" target="_top">
          <svg width="200" height="60" viewBox="0 0 188 41">
            <image href={stateConfig[state.toLowerCase()]?.logo} width="188" height="41" />
          </svg>
        </a>
        <ul className="xl:flex hidden items-center gap-10 justify-end w-full ">
          {features?.map((item) => (
            <li>
              <Link to={`/${state}/${item}`} className="2xl:text-base text-sm font-normal ">
                {item === 'search' && 'AI Search'}
                {item === 'videos' && 'Video Transcripts'}
                {item === 'lobby' && 'Lobby'}
              </Link>
            </li>
          ))}
          {auth === 'login' && (
            <li>
              <button className="border-[1px] border-white hover:bg-white/25 p-2 rounded" onClick={handleLogOut}>
                Log out
              </button>
            </li>
          )}
        </ul>
        <div className="relative xl:hidden">
          <button
            className="dropbtn flex items-center gap-2 text-white mr-2 p-1"
            onClick={() => setMobileDropdown(!mobileDropdown)}
          >
            Quick Picks
            <ThreeArrowsSVG secundary />
          </button>
          <AnimatePresence>
            {mobileDropdown && (
              <motion.div
                className="absolute bg-gray-100 min-w-[160px] shadow-lg z-10 p-3 rounded-lg top-14 overflow-hidden"
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
              >
                <div className="flex flex-col gap-2 w-max">
                  {features?.map((item) => (
                    <Link to={`/${state}/${item}`} className="2xl:text-base text-sm font-normal text-black">
                      {item === 'search' && 'AI Search'}
                      {item === 'videos' && 'Committee Transcripts'}
                      {item === 'lobby' && 'Lobby'}
                    </Link>
                  ))}
                  {auth === 'login' && (
                    <button className="border-[1px] border-white hover:bg-white/25 p-2 rounded" onClick={handleLogOut}>
                      Log out
                    </button>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      {/* <div className="xl:fixed 2xl:top-[100px] xl:!top-[100px] bg-white ml-auto right-0 w-full py-4 px-4 xl:px-8 lg:shadow-[0px_0px_25px_0px_rgba(10,24,70,0.05)] lg:flex min-[1190px]:items-center gap-14 lg:mt-0 mt-10 z-40 sm:mr-[15px]">
        <div className="flex flex-col lg:flex-row items-center gap-[15px] w-[65%]">
          <div className="flex items-center gap-3 2xl:text-base text-sm font-medium w-full">
            Quick Picks
            <ThreeArrowsSVG />
          </div>
          <Dropdown id="Bill-Number" title="Bill Number" options={["01", "02", "03", "04"]} />
          <input
            type="text"
            id="input-for-bill-no"
            className=" border border-[#E5E7EB] bg-[#F9FAFB] text-black min-w-[232px] text-base font-normal block w-full py-2 px-4 focus:outline-none placeholder:text-black/30 "
            placeholder="Enter Bill Number"
          />
          <Dropdown id="code" title="2023-R (88R)" options={["01", "02", "03", "04"]} />
          <div className="flex items-center gap-4">
            <button
              type="submit"
              className="py-3 px-8 bg-[#83B2FA] text-base font-semibold text-white transition-all duration-300 hover:bg-lightblue-hover"
            >
              Go
            </button>

            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#182158"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path d="M12 8V13" stroke="#182158" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              <path
                d="M11.9941 16H12.0031"
                stroke="#182158"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default MainHeader;
