import React, { useEffect, useState, useRef } from 'react';
// hooks
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import stateConfig from '../stateConfig';
// store
import { getSections, clearQuery, clearQueries } from '../features/bills/billSlice';
import { fetchUpdatedTracks, setSession } from '../features/user/userSlice'; // Import your new action
// components
import ReactMarkdown from 'react-markdown';
import BillQueryForm from '../components/BillQueryForm';
import ThreeArrowsSVG from '../components/SVGs/ThreeArrowsSVG';
import Dropdown from '../components/Structures/Dropdown';
import MainLoader from '../components/Structures/MainLoader';
import versionsMap from '../versionsMap';
import { IoMdArrowBack } from 'react-icons/io';
import { formatBillNumber } from '../utils';
import Tabs from '../components/Structures/TabsSwitch';

const BillPage = () => {
  const { billId, state } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const session = searchParams.get('session');

  const [isLoading, setIsLoading] = useState(true);
  const isQueryDisabled = state.toLowerCase() === 'tx' && billId === 'HB1';

  const [version, setVersion] = useState(stateConfig[state.toLowerCase()]?.defaultVersion || 'FIL');
  const [activeIndex, setActiveIndex] = useState(0);
  const [billTypes, setBillTypes] = useState([]);
  const [keyChanges, setKeyChanges] = useState('');
  const [showSectionSummary, setShowSectionSummary] = useState(true);

  const { bill, pdf, versions, billSummary, isLoading: versionLoading } = useSelector((state) => state.bill);
  const { auth, tracks, searchTerm } = useSelector((state) => state.user);

  const billsText = bill.map((bil) => bil.metadata?.text || bil.metadata?.summary || '').join('\n');

  const timeoutRef = useRef();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (session) {
      dispatch(setSession(session)); // Dispatch the action to update the session in Redux state
    }
  }, [session, dispatch]);

  useEffect(() => {
    const fetchBillData = async () => {
      try {
        const { sections, types } = await dispatch(getSections({ billId, session, version })).unwrap();

        setBillTypes(types);

        // Assuming sectionsData contains the bill text from which you extract key changes

        const allText = sections.map((section) => section.metadata?.text).join('\n');

        const extractedKeyChanges = extractKeyChanges(allText);
        setKeyChanges(extractedKeyChanges);

        // Set version to the first available if the current is not in the list
        if (versions.length > 0 && !versions.includes(version)) {
          setVersion(versions[0]);
        }
      } catch (error) {
        console.error('Failed to fetch bill data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (billId && session && auth) {
      fetchBillData();
    }
  }, [billId, session, version, auth]);

  const handleSectionClick = (index) => {
    setActiveIndex(index); // Update active section index
  };

  const handleVersionComparison = () => {
    navigate(`../compare/${billId}`);
  };

  const extractKeyChanges = (text) => {
    const keyChangesStart = text.indexOf('Key Changes');
    const otherHighlightsStart = text.indexOf('Other Highlights');
    return keyChangesStart !== -1
      ? text.substring(keyChangesStart, otherHighlightsStart !== -1 ? otherHighlightsStart : undefined).trim()
      : '';
  };

  const formatTitle = (id) => {
    // Extract section and part numbers
    const sectionMatch = id.match(/S(\d+)/);
    const partMatch = id.match(/P(\d+)$/);
    const sectionNumber = sectionMatch ? sectionMatch[1] : '';
    const partNumber = partMatch ? ` Part ${partMatch[1]}` : '';
    if (sectionNumber === '0') {
      return { title: `Bill Intro${partNumber}`, partNumber };
    } else if (sectionNumber) {
      return { title: `Section ${sectionNumber}${partNumber}`, partNumber };
    } else {
      return { title: `Bill Summary${partNumber}`, partNumber };
    }
  };

  const openPopup = () => {
    const match = searchTerm.match(/^\d+/);
    const popup = window.open(
      `${stateConfig[state.toLowerCase()].website}htbin/json_trkpop?${match[0]}${state.toUpperCase()}${session.toUpperCase()}${formatBillNumber(billId)}`,
      'popup',
      'width=600,height=600'
    );

    const popupChecker = setInterval(() => {
      if (popup.closed) {
        clearInterval(popupChecker);
        setTimeout(() => {
          dispatch(fetchUpdatedTracks(searchTerm));
        }, 3000);
      }
    }, 1000);
  };

  const billSorter = (a, b) => {
    // Generalized regex to match the bill format including different prefixes, stages, sections, and parts
    const regex = new RegExp(`^(${billTypes.join('|')})(\\d+)(${versions.join('|')})?(S(\\d+))?(P(\\d+))?$`);

    const matchA = a.id.match(regex);
    const matchB = b.id.match(regex);

    if (!matchA || !matchB) return 0; // In case some id doesn't match the pattern

    // Compare prefixes (HB, SB, etc.)
    if (matchA[1] !== matchB[1]) {
      return matchA[1].localeCompare(matchB[1]);
    }

    // Compare numeric part of the bill
    const numberA = parseInt(matchA[2], 10);
    const numberB = parseInt(matchB[2], 10);
    if (numberA !== numberB) return numberA - numberB;

    // Compare versions using a custom order
    const versionOrder = ['FIL', 'ENG']; // Define your desired version order here
    const versionA = matchA[3] || '';
    const versionB = matchB[3] || '';
    const indexA = versionOrder.indexOf(versionA);
    const indexB = versionOrder.indexOf(versionB);
    if (indexA !== indexB) return indexA - indexB;

    // Compare sections
    const sectionA = matchA[5] ? parseInt(matchA[5], 10) : 9999; // Set large default if not present
    const sectionB = matchB[5] ? parseInt(matchB[5], 10) : 9999;
    if (sectionA !== sectionB) return sectionA - sectionB;

    // Compare parts
    const partA = matchA[7] ? parseInt(matchA[7], 10) : 0; // Default to 0 if not present
    const partB = matchB[7] ? parseInt(matchB[7], 10) : 0;
    if (partA !== partB) return partA - partB;

    return 0; // Identical or no discernible order
  };

  const renderBillRecords = () => {
    const filteredBills = bill.filter((record) => record.id.includes(version));

    // Assuming bills is an array of objects where each object has an id property that needs sorting
    const billsArray = Object.values(filteredBills).sort(billSorter);

    return (
      billsArray?.length > 0 &&
      billsArray.map((record, index) => {
        const isOpen = index === activeIndex;
        const { title, partNumber } = formatTitle(record.id);
        const sectionMatch = record.id.match(/S(\d+)/);
        const sectionNumber = sectionMatch ? sectionMatch[1] : '';
        const content = record.metadata ? record.metadata.text || record.metadata.summary || '' : '';

        let keyChanges = null;
        let otherHighlights = null;
        let overallImpact = null;

        if (content) {
          // Find the start indices of each subsection
          const keyChangesStart = content.indexOf('Key Changes');
          const otherHighlightsStart = content.indexOf('Other Highlights');
          const overallImpactStart = content.indexOf('Overall Impact');

          // Function to extract subsection content based on start and end indices
          const extractContent = (start, nextStart) =>
            start !== -1
              ? content
                  .substring(start, nextStart !== -1 ? nextStart : undefined)
                  .trim()
                  .replace(
                    /[<>[\]]|Overall Impact:\*\*|Key Changes:\*\*|Overall Impact:|Key Changes:|Overall Impact|Key Changes|\*\*$/g,
                    ''
                  )
              : null;

          // Extracting subsections based on identified indices
          keyChanges = sectionNumber !== '0' ? extractContent(keyChangesStart, overallImpactStart) : null;
          // otherHighlights = extractContent(otherHighlightsStart, overallImpactStart);
          overallImpact = extractContent(overallImpactStart, -1);
        }

        return (
          <li key={index} onClick={() => handleSectionClick(index)}>
            <h5 className="font-playfair text-2xl font-medium text-[#182158] mb-5">{title}</h5>
            {keyChanges && (
              <>
                <h6 className="font-playfair font-medium text-[22px] text-black mb-[15px]">Key Changes</h6>
                <p className="text-lg font-normal leading-[30px] text-black/70 mb-5">
                  <ReactMarkdown>{keyChanges}</ReactMarkdown>
                </p>
              </>
            )}
            {overallImpact && (
              <>
                <h6 className="font-playfair font-medium text-[22px] text-black mb-[15px]">Overall Impact</h6>
                <p className="text-lg font-normal leading-[30px] text-black/70">
                  <ReactMarkdown>{overallImpact}</ReactMarkdown>
                </p>
              </>
            )}
          </li>
        );
      })
    );
  };

  return (
    <>
      {isLoading ? (
        <MainLoader />
      ) : (
        <>
          <div className="bg-[#F9FAFB] border border-[#E5E7EB] p-5 pb-[15px] w-full mb-[20px]">
            <div className="border-b border-[#E5E7EB] mb-5 pb-3.5 flex flex-col md:flex-row justify-between items-center w-full">
              <button className="hover:bg-darkblue-hover/15" onClick={() => navigate(`/${state}/search`)}>
                <IoMdArrowBack className="w-12 h-12 text-darkblue" />
              </button>
              <h2
                id="Office-Information"
                className="font-playfair text-[28px] text-darkblue font-medium leading-8 mx-2 w-full"
              >
                Bill: {billId}
              </h2>
              <button
                className="py-3 px-12 bg-[#83B2FA] text-base font-normal text-white transition-all duration-300 hover:bg-lightblue-hover w-[250px]"
                onClick={openPopup}
              >
                Add to track
              </button>
              <div className="flex flex-col sm:flex-row items-center gap-[15px]"></div>
            </div>
            <div className="flex items-center gap-[15px]">
              <div className="hidden min-[1161px]:flex items-center gap-3 text-base font-medium text-black">
                Version
                <ThreeArrowsSVG />
              </div>
              <div className="flex flex-col lg:flex-row w-full items-center gap-[15px]">
                <Dropdown
                  id="Numberofitem"
                  options={versions?.map((ver) => (versionsMap[ver] ? { label: versionsMap[ver], value: ver } : ver))}
                  title={versions.length > 0 ? 'Versions' : 'Loading versions...'}
                  value={version}
                  label={null}
                  onChange={(e) => {
                    setVersion(e.target.value);
                    dispatch(clearQuery({ auth }));
                    dispatch(clearQueries());
                  }}
                />
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    className="btn btn-enabled"
                    disabled={versions.length <= 1}
                    onClick={handleVersionComparison}
                  >
                    Compare Versions
                  </button>
                </div>
              </div>
            </div>
          </div>
          {versionLoading ? (
            <MainLoader />
          ) : (
            <>
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-[30px] w-full mb-[20px]">
                <div className="">
                  <div className=" bg-[#F9FAFB] w-full h-full">
                    <iframe
                      class="w-full h-[1220px]"
                      id="bill-pdf"
                      src={pdf} // Ensure your state or reducer updates the pdf link accordingly
                      title="PDF Viewer"
                      frameborder="0"
                    ></iframe>
                  </div>
                </div>
                <div className="">
                  <BillQueryForm
                    billText={billsText}
                    version={version}
                    billId={billId}
                    keyChanges={keyChanges}
                    session={session}
                    isQueryDisabled={isQueryDisabled}
                  />
                  <div className="bg-[#F9FAFB] border border-[#E5E7EB] p-5 pt-[15px]">
                    <div className="border-b border-[#E5E7EB] mb-5 pb-2.5">
                      <h2 className="font-playfair text-[28px] text-darkblue font-medium leading-8">
                        AI {showSectionSummary ? 'Section' : 'Bill'} Summary
                      </h2>
                    </div>
                    <Tabs
                      options={[
                        { label: 'Bill Summary', value: false },
                        { label: 'Section Summary', value: true },
                      ]}
                      isActive={showSectionSummary}
                      onSwitch={() => setShowSectionSummary(!showSectionSummary)}
                    />
                    {showSectionSummary ? (
                      <ul className="flex flex-col gap-[30px]">{renderBillRecords()}</ul>
                    ) : (
                      <div>
                        <h6 className="font-playfair font-medium text-[22px] text-black mb-5">Key Changes</h6>
                        <p className="text-lg font-normal leading-[30px] text-black/70 mb-5">
                          <ReactMarkdown>{billSummary?.text.replace('Key Changes', '') || ''}</ReactMarkdown>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="insufficient">
                The responses on this website are generated by artificial intelligence tools and do not reflect the
                views or opinions of our company. Please verify information independently.
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BillPage;
